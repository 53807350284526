import {Details, OrderingOptions} from '@/features/listings/types';
import {ListingItem} from '@/features/types';

export type TProps = {
  value: keyof Details;
  text: string;
  border: boolean;
};

type SortProps = {
  title: string;
}[];

export type TInfoProps = {
  value: keyof Details;
  text: string;
};
export type TDocumentType = {
  id: number;
  text: string;
};
export const HOUSE_PARAMS: TProps[] = [
  {value: 'total_bedrooms', text: 'Beds', border: true},
  {value: 'total_bathrooms', text: 'Baths', border: true},
  {value: 'building_area', text: 'Sq. Ft.', border: true},
  {value: 'lot_dimensions', text: 'Lot Size', border: false},
];

export const LISTING_PARAMS: TInfoProps[] = [
  {value: 'date_created', text: 'Days on Offerwell'},
  {value: 'property_type', text: 'Property Type:'},
  {value: 'total_completed_offers', text: 'Offers Submitted:'},
  {value: 'views', text: 'Views:'},
];
export const DOCUMENT_TYPE: TDocumentType[] = [
  {id: 0, text: 'Attachment A'},
  {id: 1, text: 'Attachment B'},
  {id: 2, text: 'Property Condition Disclosure Statement'},
  {id: 3, text: 'Rented Property Rider'},
  {id: 4, text: 'Delayed Showing Negotiation'},
  {id: 5, text: 'Vacant Land Rider'},
  {id: 6, text: 'Agricultural Rider'},
  {id: 7, text: 'Condominium Homeowners Association Rider'},
  {id: 8, text: 'Other'},
  {id: 9, text: 'All'},
];
export const LISTING_DOCUMENT_TYPE_WITHOUT: TDocumentType[] = [
  {id: 0, text: 'Attachment A'},
  {id: 1, text: 'Attachment B'},
  {id: 2, text: 'Property Condition Disclosure Statement'},
  {id: 3, text: 'Rented Property Rider'},
  {id: 4, text: 'Delayed Showing Negotiation'},
  {id: 5, text: 'Vacant Land Rider'},
  {id: 6, text: 'Agricultural Rider'},
  {id: 7, text: 'Condominium Homeowners Association Rider'},
  {id: 8, text: 'Other'},
];

export const DEMO_CARDS: ListingItem[] = [
  {
    full_address: '',
    new_actions_count: 0,
    listing_key: '',
    listing_id: '1',
    agent_name: '',
    date_modified: '',
    date_created: '',
    days_on_market_date: '',
    role: 'Listing Agent',
    can_edit_offers: true,
    can_view_offers: true,
    price: 0,
    deadline_datetime: '',
    std_status: '',
    property_subtype: '',
    property_type: '',
    property_type_abbrv: '',
    setup_complete: true,
    std_status_display: '',
    main_img: '',
    main_pic: '',
    client: {
      id: 0,
      first_name: '',
      last_name: '',
    },
    total_completed_offers: 0,
    days_on_market: 0,
    broker_name: '',
    is_archived: true,
    is_paid: false,
    is_accepting_offers: true,
    showing_details: {
      status_display: 'Disabled',
      status: 1,
      first_available_slot: {from: '', to: ''},
      accept_showings: false,
    },
  },
  {
    full_address: '',
    listing_key: '',
    new_actions_count: 0,
    listing_id: '2',
    agent_name: '',
    date_modified: '',
    date_created: '',
    days_on_market_date: '',
    role: 'Listing Agent',
    can_edit_offers: true,
    can_view_offers: true,
    price: 0,
    deadline_datetime: '',
    std_status: '',
    property_subtype: '',
    property_type: '',
    property_type_abbrv: '',
    setup_complete: true,
    std_status_display: '',
    main_img: '',
    main_pic: '',
    client: {
      id: 0,
      first_name: '',
      last_name: '',
    },
    total_completed_offers: 0,
    days_on_market: 0,
    broker_name: '',
    is_archived: true,
    is_paid: false,
    is_accepting_offers: true,
    showing_details: {
      status_display: 'Disabled',
      status: 1,
      first_available_slot: {from: '', to: ''},
      accept_showings: false,
    },
  },
  {
    full_address: '',
    listing_key: '',
    new_actions_count: 0,
    listing_id: '3',
    agent_name: '',
    date_modified: '',
    date_created: '',
    days_on_market_date: '',
    role: 'Listing Agent',
    can_edit_offers: true,
    can_view_offers: true,
    price: 0,
    deadline_datetime: '',
    std_status: '',
    property_subtype: '',
    property_type: '',
    property_type_abbrv: '',
    setup_complete: true,
    std_status_display: '',
    main_img: '',
    main_pic: '',
    client: {
      id: 0,
      first_name: '',
      last_name: '',
    },
    total_completed_offers: 0,
    days_on_market: 0,
    broker_name: '',
    is_archived: true,
    is_paid: false,
    is_accepting_offers: true,
    showing_details: {
      status_display: 'Disabled',
      status: 1,
      first_available_slot: {from: '', to: ''},
      accept_showings: false,
    },
  },
];

export const STATUS = {
  pending: 'Pending',
  active: 'Active',
  continueToShow: 'ActiveUnderContract',
  canceled: 'Canceled',
  closed: 'Closed',
  comingSoon: 'ComingSoon',
  delete: 'Delete',
  expired: 'Expired',
  hold: 'Hold',
  incomplete: 'Incomplete',
  withdrawn: 'Withdrawn',
} as const;

export const SORT_BY_OPTIONS: OrderingOptions[] = [
  '-date_created',
  'address',
  'price',
  '-price',
];

export const NEW_SORT_ALL_LISTING: SortProps = [
  {title: 'Most recent'},
  {title: 'Address (A-Z)'},
  {title: 'Ascending price'},
  {title: 'Descending price'},
];

export const PROP_TYPE_SUFFIX = {
  SingleFamilyResidential: 'SFR',
  Residential: 'SFR',
  CondoAndTownhouse: 'CND',
  Commercial: 'COM',
  MultiFamily: 'MUL',
  LotsLandFarmsSeasonalCamps: 'LOT',
  UnitApartments: 'APT',
};
