import {GetAgentMetricsResponse} from '@/features/listings/types';

type TProps = {
  title: string;
}[];
type TClientFilterProps = {
  title: string;
  icon?: number;
}[];

type TTopProps = {
  value: keyof GetAgentMetricsResponse;
  title: string;
  icon?: number;
}[];
export type TClientProps = {
  name: string;
  email: string;
  number: string;
  type: number;
  status: number;
  offerCount: number;
  listingCount: number;
  statusText: string;
};
export type THouseProps = {
  address: string;
  property: string;
  price: string;
  offerPrice: string;
  name: string;
  status: number;
};

export const TOP_INFO: TTopProps = [
  {value: 'total_active_listings', title: 'Listings Active'},
  {value: 'total_submitted_offers', title: 'Submitted Offers'},
  {value: 'total_buyers', title: 'Buyers'},
  {value: 'total_sellers', title: 'Sellers'},
];
export const CLIENT_TOP_INFO: TProps = [
  {title: 'My Offers'},
  {title: 'My Listings'},
];

export const CLIENT_TYPE_NAME = ['Agent', 'Offers', 'Listings'];

export const FILTER_CLIENTS: TClientFilterProps = [
  {title: 'Select/Deselect All'},
  {title: 'Buyers', icon: 1},
  {title: 'Sellers', icon: 2},
];
export const FILTER_OFFERS: TProps = [
  {title: 'Select/Deselect All'},
  {title: 'Offer Sent'},
  {title: 'Offer Seen'},
  {title: 'Accepted'},
  {title: 'Declined'},
  {title: 'Counter Sent'},
  {title: 'Counter Recieved'},
  {title: 'Counter Accepted'},
  {title: 'Counter Declined'},
  {title: 'Incomplete'},
];
export const FILTER_LISTINGS: TProps = [
  {title: 'Select/Deselect All'},
  {title: 'Active'},
  {title: 'Under Contract'},
  {title: 'Continue To Show'},
  {title: 'Not Set Up'},
  {title: 'Pending'},
  {title: 'Closed'},
];

export const FILTER_DASHBOARD_OFFER: TProps = [
  {title: 'Select/Deselect All'},
  {title: 'Favorites'},
  {title: 'New Offers'},
  {title: 'Offer Seen'},
  {title: 'Counter Recieved'},
  {title: 'Counter Sent'},
];
export const FILTER_TYPE = {
  listings: FILTER_LISTINGS,
  offers: FILTER_OFFERS,
  dashboardOffers: FILTER_DASHBOARD_OFFER,
};

export const SORT_CLIENTS: TProps = [
  {title: 'Name'},
  {title: 'Recently Added'},
  // {value: '6', title: 'Activity'},
];
export const SORT_OFFERS: TProps = [
  {title: 'Recent Activity'},
  {title: 'Address'},
  {title: 'Asking Price | Asc.'},
  {title: 'Asking Price | Desc.'},
  {title: 'Offer | Asc.'},
  {title: 'Offer | Desc.'},
];

export const NEW_SORT_OFFERS: TProps = [
  {title: 'Recent activity'},
  {title: 'Address (A-Z)'},
  {title: 'Ascending asking price'},
  {title: 'Descending asking price'},
];

export const SORT_LISTINGS: TProps = [
  {title: 'Recent Activity'},
  {title: 'Address'},
  {title: 'Asking Price | Asc.'},
  {title: 'Asking Price | Desc.'},
];

export const SORT_DASHBOARD_OFFER: TProps = [
  {title: 'Activity'},
  {title: 'Address'},
  {title: 'Asking Price | Asc.'},
  {title: 'Asking Price | Desc.'},
  {title: 'Offer | Asc.'},
  {title: 'Offer | Desc.'},
];

export const SORT_SHOWINGS: TProps = [
  {title: 'Closest date & time'},
  {title: 'View Details'},
  {title: 'Address (A-Z)'},
];
export const SORT_NEW_SHOWINGS: TProps = [
  {title: 'Date (Closest)'},
  {title: 'Date (Furthest away)'},
];

export const SORT_TYPE = {
  listings: SORT_LISTINGS,
  offers: SORT_OFFERS,
  dashboard: SORT_DASHBOARD_OFFER,
  newOffers: NEW_SORT_OFFERS,
  showings: SORT_SHOWINGS,
  newShowings: SORT_NEW_SHOWINGS,
};
